import React from 'react'
import { Redirect, Layout, Hero, Container, Section } from 'components'
import locales from 'config/locales'
import heroImage from 'images/yoga-hero.jpg'
import yogaStance from 'images/yoga-stance.jpg'
import yogaMeditation from 'images/yoga-meditation.jpg'
// Photo by kazuend on Unsplash

const images = [{ src: yogaMeditation }, { src: yogaStance }]

export const YogaPage = ({ language }) => {
  const locale = locales[language]
  const page = locale.pages.yoga
  return (
    <Layout language={language}>
      <Hero
        backgroundImage={heroImage}
        backgroundPosition={{ _: 'center 28%', sm: 'center 34%' }}
      />
      <Container marginBottom={8} marginTop={2}>
        <Section type={'subsections'} {...page.yoga} />
        <Section type={'images'} images={images} />
        <Section type={'text'} {...page.health} />
        <Section type={'text'} {...page.body} />
      </Container>
    </Layout>
  )
}

export default Redirect
